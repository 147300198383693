import React, {Component} from 'react';
import SectionTitle from '../SectionTitle.jsx';
import {formatDate, convertCurrency, cleanWhiteSpace} from '../utils/utils.js'
import ErrorBoundary from "../ErrorBoundary";
import axios from 'axios';
// Material Ui Components
import Grid from "@material-ui/core/Grid";
import {enterComponent} from '../animated.js'
// Custom Templates
import EndlessTable from '../EndlessTable';
// SASS Styles
import style from "../styles/AccountSummary.module.scss";
import {catchAxios} from "../utils/utils";
import LoaderPage from "../LoaderPage";

const EnterGrid = enterComponent(Grid)

/**
 * Gets payment history and then renders data as an endless scrolling table.
 * @module PaymentHistory
 * @extends Component
 */
class PaymentHistory extends Component {
    _isMounted = false;

    state = {
        headers: ['POSTING DATE', 'PAYMENT METHOD','DESCRIPTION', 'INVOICE DATE', 'INVOICE PAID'],
        loading: true,
        error: '',
        success: '',
        hasMore: true,
        data: [],
        offset: 0,
        limit: 15,
    };

    /**
     * Formats payment history data for output.
     * @function buildData
     */
    buildData = (data) => {
        return data.map(e => {
            return [
                formatDate(e.postingdate),
                e.paymentmethod,
                cleanWhiteSpace(e.paymentinfo),
                formatDate(e.invoicedate),
                e.billingstatementid
            ]
        });
    };

    /**
     * Gets payment data from server using offset and limit.
     * @function getData
     */
    getData = () => {
        const authToken = sessionStorage.getItem('token');

        return axios({
            method: 'post',
            url: '/invoices/pay-history',
            headers: {
                'Authorization': `Bearer ${authToken}`,
            },
            data: {
                offset: this.state.offset,
                limit: this.state.limit
            }
        }).then((response) => {
            if (this._isMounted) {
                this.setState({loading: false});

                // Check for correct response format
                if (response.data && response.data.data) {
                    if (response.data.status === true) {
                        this.setState({
                            success: response.data.msg,
                            data: [...this.state.data, ...response.data.data],
                            offset: this.state.offset + this.state.limit,
                            hasMore: (response.data.data.length >= this.state.limit)
                        });
                    } else if (response.data.status === false) {
                        this.setState({error: response.data.msg});
                    }
                } else {
                    this.setState({error: 'There was a problem retrieving your payment data.'});
                }
            }
        }).catch((err) => {
            this.setState({loading: false});
            this.setState({error: 'There was a problem retrieving your payment data.'});
            catchAxios(err);
        });
    };

    componentDidMount() {
        this._isMounted = true;
        this.getData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const {data} = this.state;
        const rows = this.buildData(data);

        return (
            <>
                <LoaderPage/>
                <div>
                    <ErrorBoundary>
                        <SectionTitle variant="h1" style={style.welcome} title="Payment History"/>
                    </ErrorBoundary>
                    <div className={`${style.container} ${style.accDetails}`}
                         style={{minHeight: '0', padding: '5vh 2vw'}}>
                        <Grid container justify="center">
                            <EnterGrid item xs={12}>
                                <ErrorBoundary>
                                    <EndlessTable
                                        length={rows.length}
                                        loading={this.state.loading}
                                        hasMore={this.state.hasMore}
                                        next={this.getData}
                                        headers={this.state.headers}
                                        rows={rows}
                                        // No height if the number of rows would be smaller than the container
                                        tableHeight={(rows && rows.length >= this.state.limit) ? '65vh' : null}
                                        minCellWidth={130}
                                        dataName="payment data"
                                        style={{maxWidth: '95%', margin: '0', width: '80vw'}}
                                    />
                                </ErrorBoundary>
                            </EnterGrid>
                        </Grid>
                    </div>
                </div>
            </>
        )
    }
}

export default PaymentHistory

import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {generateKey} from "./utils/utils";
import style from './styles/Aside.module.scss';
import History from './History.js';
import NavLogo from './NavLogo.jsx';
import {AppContext} from '../contexts/PortalContext.js';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import BackButton from './BackButton.js';
import {clearServerSession, decodeToken} from './utils/utils';
import Loading from './Loading';
import MailOutlineIcon from "@material-ui/icons/MailOutline";

// Material-UI
import {
    AppBar,
    CssBaseline,
    Drawer,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Toolbar,
    Typography,
    Collapse,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,
  Tooltip
} from "@material-ui/core";
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {siteType} from "../App";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        color: theme.palette.common.white,
        background: theme.menuPaper.background,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    nested: {
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white
    },
    history: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    active: {
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        }
    },
}));

function NestedDrawer(props) {
    const {container} = props;
    const classes = useStyles();
    const theme = useTheme();
    const context = useContext(AppContext);
    let activeBillingAccount = null;
    const token = decodeToken(sessionStorage.getItem('token'));
    const multiAccount = !!(token && token.user && token.user.multiAccount);  // simplifies a true / false ternary.
    const isAdmin = token.user.isAdmin;
    const [unreadNotificationsCountLoading, setUnreadNotificationsCountLoading] =
    useState(true);
    const [unreadNotificationsCountError, setUnreadNotificationsCountError] =
    useState(false);
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState([]);
    if (context && context.state) {
        activeBillingAccount = context.state.activeBillingAccount;
    }

    //  HOOKS
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [loading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [sectionSelected, setSectionSelected] = React.useState(false);

    // handles mobile navigation toggle
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    // Drawer Submenu Open
    const handleClick = async () => {
        setOpen(!open);
        setMobileOpen(!mobileOpen);
    };

    // Modal operation used to confirm logout
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const logOut = async () => {
        await context.state.dispatch({
            type: 'SET_GLOBAL_LOAD',
            payload: true,
        });
        await context.state.dispatch({
            type: 'LOGOUT_USER'
        });
        await context.state.dispatch({
            type: 'SET_ACTIVE_BILLING_ACCOUNT',
            payload: '0'
        });
        await context.state.dispatch({
            type: 'SET_BUSINESS_UNIT',
            payload: siteType(),
        });
        await clearServerSession();
        await context.state.dispatch({
            type: 'SET_GLOBAL_LOAD',
            payload: false,
        });
        props.history.push('/');
        toggleModal();
    };

    // Used for checking router location path against item link
    const samePath = (location) => {
        if (location !== '/business') {
            let routerLocation = props.history.location.pathname;
            return !!routerLocation.includes(location);
        }
        return false
    };

    let navigation = []; // Only include certain navigation components depending on context

    // Regular account navigation for when an account has been selected
    const accountNavigation = [
        {
            title: 'My Account', link: '/business/my-account', icon: ['far', 'user-circle'],
            submenu: [
                {title: 'Account Details', link: '/business/my-account/account-details'},
                {title: 'Change Password', link: '/business/my-account/password-reset'},
                //{title: 'Change Details', link: '/business/my-account/change-account-details/billing-address'}
            ]
        },
         {
      title: "Documents",
      link: "/business/documents",
      icon: ["far", "file-alt"],
      submenu:
        context &&
        context.state &&
        context.state.voice &&
        context.state.voice.voice
          ? [
              {
                title: "Call Detail Record",
                link: "/business/documents/call-details",
              },
              { title: "My Invoices", link: "/business/documents/my-invoices" },
              {
                title: "Payment History",
                link: "/business/documents/payment-history",
              },
{
title: "Notifications",
link: "/business/documents/notifications",
},  
          ]
          : [
              { title: "My Invoices", link: "/business/documents/my-invoices" },
              {
                title: "Payment History",
                link: "/business/documents/payment-history",
              },
{
title: "Notifications",
link: "/business/documents/notifications",
},            
],
    },
	{
            title: 'Payments', link: '/business/payments', icon: ['fal', 'credit-card-front'],
            submenu: [
                {title: 'Make a Payment', link: '/business/payments/make-payment'},
                {title: 'Add Payment Method', link: '/business/payments/add-new-card'},
                {title: 'Payment Methods', link: '/business/payments/payment-methods'},
                {title: 'Payment History', link: '/business/payments/payment-history'}
            ]
        },
        {
            title: 'Help', link: '/business/help', icon: ['far', 'question-circle'],
            submenu: [
                {title: 'Quick Guide', link: '/business/help/quick-guide'},
                {title: 'Contact Us', link: '/business/help/contact-us'}
            ]
        }];

    // Admin navigation only
    const adminLinks = [{
        title: 'Admin Tools', link: '/admin', icon: ['fas', 'user-shield'],
        submenu: [
            {title: 'Statistics', link: '/admin/stats'},
            {title: 'Live Users', link: '/admin/live-users'},
            {title: 'Switch Account', link: '/business'}
        ]
    }];

    // SHow standard nav if billing account is selected or user is not an admin
    if (activeBillingAccount || !isAdmin) {
        navigation = [...navigation, ...accountNavigation];
    }

    //if a user is an admin, append the admin menu to the end of the navigation array.
    if (token.user.isAdmin) {
        navigation = [...navigation, ...adminLinks];
    }

    // Only render switch account link if user has multiple accounts
    if (multiAccount) {
        // Add switch account link into navigation
        const switchLink = {title: 'Switch Account', link: '/business'};
        accountNavigation[0].submenu.splice(2, 0, switchLink);
    }

useEffect(() => {
    const loadNotificationsCount = async () => {
      try {
if (context && context.state && context.state.activeBillingAccount) {
        const authToken = sessionStorage.token;
        const response = await axios({
          method: "GET",
          url: "/notifications/my/unread/" + context.state.activeBillingAccount,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          withCredentials: true,
        });
        const { data } = response;
        setUnreadNotificationsCount(data.data);
context.state.dispatch({
type: "SET_UNREAD_COUNT",
payload: { unreadCount: data.data },
});    
}  
} catch (err) {
        setUnreadNotificationsCountError(true);
      } finally {
        setUnreadNotificationsCountLoading(false);
      }
    };
    loadNotificationsCount();
  }, [context && context.state && context.state.activeBillingAccount]);

    const drawer = (
        <div className={style.container}>
            <div className={classes.toolbar}>
                <NavLogo/>
            </div>
            <List component="div">
                {navigation.map((e) => (
                    <div key={e.title}>
                        <Link to={e.link} onClick={handleClick}>
                            <ListItem button className={samePath(e.link) ? `${classes.active}` : ''}
                                      onClick={() => setSectionSelected(!sectionSelected)} component="div">
                                <ListItemText primary={e.title}/>
                                <div className={style.iconContainer}>
                                    <FontAwesomeIcon icon={e.icon}/>
                                </div>
                            </ListItem>
                        </Link>
                        <Collapse in={samePath(e.link)} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {
                                    e.submenu.map(e => {
                                        return (
                                            <Link to={{pathname: e.link, state: {from: props.location.pathname}}}
                                                  key={generateKey(e.title)}
                                                  onClick={handleClick}>
                                                <ListItem component="div" button
                                                          className={`${classes.nested} ${samePath(e.link) && style.activeSublink}`}>
                                                    <ListItemText primary={e.title}/>
                                                </ListItem>
                                            </Link>
                                        )
                                    })
                                }
                            </List>
                        </Collapse>
                    </div>
                ))}
            </List>
            <List component="div">
                {[{title: 'LogOut', link: '/', icon: ['far', 'sign-out-alt']}].map((e) => (
                    <div onClick={toggleModal} key={generateKey(e.title)} className={style.list_item}>
                        <ListItem button component="div">
                            <ListItemText primary={e.title}/>
                            <div className={style.iconContainer}>
                                <FontAwesomeIcon icon={e.icon}/>
                            </div>
                        </ListItem>
                    </div>
                ))}
            </List>
        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={style.history}>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <FontAwesomeIcon icon={['far', 'bars']} className={style.toggle}/>
                    </IconButton>
                    <Typography variant="h4" noWrap>
                        <History/>
                    </Typography>
<div className={style.rightContainer}>
            <Link
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                gap: "8px",
              }}
              to={{
                pathname: "/business/documents/notifications",
              }}
            >
              <Tooltip title="Notifications">
                <MailOutlineIcon />
              </Tooltip>
{!unreadNotificationsCountError &&
context &&
context.state && context.state.unreadCount &&
context.state.unreadCount.unreadCount > 0 && (
<div className={style.count}>
<Typography variant="h5" noWrap>
{context.state.unreadCount.unreadCount &&
context.state.unreadCount.unreadCount > 3
? "3+"
: context.state.unreadCount.unreadCount}
</Typography>
</div>
)}            
</Link>
            <BackButton history={props.history} />
          </div>                
</Toolbar>
            </AppBar>
            <nav className={classes.drawer}>
                <Hidden mdUp implementation="js">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="js">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <Dialog
                open={modalOpen}
                onClose={toggleModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure you want to logout?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please confirm that you want to logout from the application.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={toggleModal}
                        variant="contained"
                        style={{minWidth: '120px'}}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={logOut}
                        variant="contained"
                        color="primary"
                        style={{minWidth: '120px'}}
                        disabled={loading}
                        autoFocus
                    >
                        <Loading
                            loading={loading}
                            loadingText="LOADING"
                            submitText="LOGOUT"
                        />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

// Let drawer access router location
const DrawerWithRouter = withRouter(NestedDrawer);
export default DrawerWithRouter;

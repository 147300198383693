import React from "react";
import SectionTitle from "../SectionTitle.jsx";
import style from "../styles/AccountSummary.module.scss";
import Grid from "@material-ui/core/Grid";
import ArchivedNotificationsDataContainer from "./ArchivedNotificationsDataContainer.js";
import LoaderPage from "../LoaderPage.jsx";

const ArchivedNotifications = () => {
  const additionalStyles = {
    margin: "0",
    maxWidth: "100%",
  };

  return (
    <>
      <LoaderPage />
      <div className={style.gridFix}>
        <SectionTitle
          variant="h1"
          title="Archive"
          style={style.welcome}
        />
        <div style={{ height: "2vh" }}>&nbsp;</div>
        <div
          className={`${style.container} ${style.accDetails}`}
          style={{ minHeight: 0, padding: "0 8px" }}
        >
          <Grid container spacing={1}>
            <ArchivedNotificationsDataContainer
              additionalStyles={additionalStyles}
              limit={5}
            />
          </Grid>
        </div>
      </div>
    </>
  );
};

export default ArchivedNotifications;



import React from "react";
import SectionTitle from "../SectionTitle.jsx";
import style from "../styles/AccountSummary.module.scss";
import Grid from "@material-ui/core/Grid";
import InvoiceDataContainer from "./InvoiceDataContainer.js";
import LoaderPage from "../LoaderPage";

/**
 * Landing page for user documents. Renders phone data, payments, and invoice containers.
 * @module DocumentsLanding
 */
function DocumentsLanding() {
  // This is a hack to get invoice margins working for document landing page
  const additionalStyles = {
    margin: "0",
    maxWidth: "100%",
  };

  return (
    <>
      <LoaderPage />
      <div className={style.gridFix}>
        <SectionTitle variant="h1" title="My Documents" style={style.welcome} />
        <div style={{ height: "2vh" }}>&nbsp;</div>
        <SectionTitle variant="h3" title="Latest Documents" />
        <div
          className={`${style.container} ${style.accDetails}`}
          style={{ minHeight: 0, padding: "0 8px" }}
        >
          <Grid container spacing={1}>
            <InvoiceDataContainer
              additionalStyles={additionalStyles}
              limit={5}
            />
          </Grid>
        </div>
      </div>
    </>
  );
}

export default DocumentsLanding;

import React, { Component } from "react";
import axios from "axios";
import qs from "qs";
import EndlessNotificationTable from "../EndlessNotificationTable";
import { formatDate } from "../utils/utils.js";
import { catchAxios } from "../utils/utils";
import { AppContext } from "../../contexts/PortalContext.js";

const EXCLUDED_NOTIFICATION_IDS = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
];

const API_HOST = process.env.REACT_APP_API_HOST;

/**
 *  * Gets notifications data and then renders NotificationsTable
 *   * @param {Object} props Loading prop and additional styles
 *    * @module NotificationsDataContainer
 *     * @extends Component
 *      */
class NotificationsDataContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    let limit = 0;
    if (props.limit) {
      limit = props.limit;
    }
    this.state = {
      success: "",
      error: "",
      loading: true,
      data: [],
      limit,
    };
  }

  handleSubmit = (file) => {
    const data = {
      fileName: file,
    };

    const authToken = sessionStorage.getItem("token");
    if (authToken) {
      axios({
        method: "POST",
        url: "/notifications/document",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${authToken}`,
        },
        data: qs.stringify(data),
        responseType: "blob",
        withCredentials: true,
      })
        .then(({ data }) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", file);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((err) => {
          catchAxios(err);
        });
    }
  };

  sortData = (data) => {
    data.sort(function (a, b) {
      return (
        new Date(formatDate(b.create_date)) -
        new Date(formatDate(a.create_date))
      );
    });
  };

  buildData = (data) => {
    if (!data) {
      return [];
    }
    if (data.length === 0) {
      return data;
    }

    return data.map((e) => {
      let readStatus = e.readAt ? "Read" : "Unread";
      if (EXCLUDED_NOTIFICATION_IDS.includes(e.id)) {
        if (readStatus === "Unread") {
          readStatus = "NO_STATUS";
        }
      }
      return [
        e.name,
        e.file,
        new Date(e.createDate).toLocaleDateString(),
        readStatus,
      ];
    });
  };

  getNotificationData = () => {
    this.setState({
      success: "",
      error: "",
      loading: true,
    });
    const authToken = sessionStorage.getItem("token");
    if (authToken) {
      axios({
        method: "GET",
        data: { limit: this.state.limit },
        url: `/notifications/archived/my/${this.context.state.activeBillingAccount}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        withCredentials: true,
      })
        .then((response) => {
          if (this._isMounted) {
            this.setState({
              success: response.data.msg,
              data: response.data.data,
              loading: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            error: "There was a problem retrieving your notifications data.",
            loading: false,
          });
          catchAxios(err);
        });
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.getNotificationData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let data = this.buildData(this.state.data);

    return (
      <>
        <EndlessNotificationTable
          length={data.length}
          loading={this.state.loading}
          hasMore={false}
          next={() => null}
          headers={["TITLE", "PDF LINK", "DATE", "STATUS"]}
          rows={data}
          refreshData={() => this.getNotificationData()}
          dataFull={this.state.data}
          tableHeight="65vh"
          minCellWidth={130}
          dataName="notification data"
          style={{ maxWidth: "95%", margin: "0", width: "100%" }}
        />
      </>
    );
  }
}

NotificationsDataContainer.contextType = AppContext;
export default NotificationsDataContainer;

